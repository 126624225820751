import React from "react";

const Footer = () => {
    return (
        <section className="footer py-2">
            <div className="container-fluid">
                <div className=" row justify-content-between px-lg-5">
                    <div className="col-lg-5 col-md-6 pt-1">
                        <p>Copyright © 2023 Smartladders Marketing Solutions</p>
                    </div>
                    <div className="col-lg-4 col-md-6 text-md-end">
                        <div className="social">
                            <ul>
                                <li><a href="https://www.facebook.com/SmartLadders" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f" /></a></li>
                                <li><a href="https://twitter.com/SmartLadders" target="_blank" rel="noreferrer"><i className="fab fa-twitter" /></a></li>
                                <li><a href="https://www.linkedin.com/company/smartladders-marketing-solutions" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in" /></a></li>
                                <li><a href="https://www.instagram.com/smartladders/?igshid=w9fnlcvjk17e" target="_blank" rel="noreferrer"><i className="fab fa-instagram" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Footer;