import { useLocation } from 'react-router-dom';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import corner from './image/banner.png';
import emoji from './image/emoji.png';
import loader from './image/giphy.gif';
import HubspotForm from 'react-hubspot-form';
import Footer from  "../components/Footer";

function Card() {
  const { state } = useLocation();
  const [Datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPost();
  }, []);

  const loadPost = async () => {
    const result = await axios.get(`https://imgserver.onrender.com/data`);
    setDatas(result.data);

  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  setTimeout(() => {
   setLoading(false)
}, 3000);
  return (
    <div>
      
    {loading ? (
      <div className="text-center justify-content-center pt-5">
        <div className="loading pt-5">
        <img src={loader} alt="icons" className="img-fluid corner" />
    </div>
      </div>
    ) : (<>
    <section className="home-banner">
      <div className="container">
        <div className="corner-img">
          <img src={corner} alt="icons" className="img-fluid corner" />
        </div>
        <div className="text-center">
          <h1 className="mb-5">Free Blog Post Ideas Generator</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-center pb-3">
              <div className="col-lg-4" key={Datas.id}>
                <div className="card text-dark border-0 p-1">
                  <div className="card-body">


                    {Datas.slice(0, 5).map(post => (
                      <p className="card-title mb-3" key={post.id}>{post.data.replace('String', state[Math.floor(Math.random() * state.length)])}</p>
                    )) }

                  </div>
                </div>
                <h4 className="p-3 text-center">Blogs for a week</h4>
              </div>
              <div className="col-lg-5 mx-lg-5">
            <div className="text-dark border-0">
              <div className="unlock-block">
                <p className="text-center pb-3">Smartladders Blog Idea Generator lets you create catchy titles for your next blog post, podcast, or video. Idea Generator will create a creative title and advice to take it to the next level.</p>
              </div>
            </div>
            <div className="text-center mx-5">
              <>
                <Button variant="success" className="py-2 px-sm-5 generate-btn" onClick={handleShow}>
                  Unlock 100 More Blog Ideas for year <img src={emoji} alt="icons" className="img-fluid emoji" />
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton className="form-color">
                  </Modal.Header>
                  <Modal.Body className="form-color px-5"><h4 className="text-center mb-5">Enter Your Details Below to Access a Year's Worth of Blog Ideas</h4>

                    <HubspotForm
                      region="na1"
                      portalId="340582"
                      formId="4c7f1578-0aba-4dd6-a683-766ee27d37a4"
                      onSubmit={() => console.log("dshgd")}

                      loading={<div>Loading...</div>}
                    />

                  </Modal.Body>
                </Modal>
              </>
            </div>
          </div>
            </div>
          </div>


        </div>

      </div>
    </section><Footer /></>
    )}
     </div>
  )
}

export default Card;
