
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import img from './image/icon.png';
import corner from './image/banner.png';
import Footer from  "../components/Footer";


const Ideas = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [finalValue, setFinalValue] = useState([]);

    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const HandleChange = (e) => {
     
            const result = e.target.value.replace(/[^a-zA-Z\s-/.,]/, '');
            setValue(result);
        
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        if(finalValue.length < 3){
            if(value.length > 0){
                setFinalValue([...finalValue, capitalizeWords(value)]);
                setValue('');
            }
        }   
        else{
            alert('Only 3 nouns are allowed...!')
        }  
       
    }

    const createPost = () => {
        sessionStorage.setItem("state", finalValue)
        console.log(finalValue)
        console.log(finalValue.length)
        if (finalValue.length === 0) {
            alert('Please enter the words');
        }
       else {
            navigate('/Card',
                {
                    state: finalValue
                });
        }  

    }
    return (
        <>
        <section className="home-banner height pt-lg-5">
            <div className="container pt-lg-3">
                <div className="corner-img">
                    <img src={corner} alt="icons" className="img-fluid corner" />
                </div>
                <div className="text-center">
                    <h1 className="mb-3">Free Blog Post Ideas Generator</h1>
                    <p className="mb-0">Enter a noun and relax!!!</p>
                    <p className="mb-0">Our engine will work for you and suggest an awesome idea!!!</p>
                    <img src={img} alt="icons" className="img-fluid icon-img pt-5" />
                </div>
                <div className="row justify-content-center pt-0">
                    <div className="col-lg-5 col-md-10">
                        <form onSubmit={HandleSubmit}>
                            <div className="input-group news-input">

                                <input style={{ textTransform: 'capitalize' }}
                                    placeholder="Enter A Noun To Get Started"
                                    type="text"
                                    value={value}
                                    className="form-control border-0 rounded-start p-3"
                                    onChange={HandleChange}
                                />
                                <button type="submit" className="btn btn-primary btn-lg rounded-end p-2 px-4 input-btn">Add</button>
                            </div>

                            <div className="mt-4">{finalValue.map((finalvalue, index) => {
                                return (
                                    <h5 key={index}>{finalvalue}  </h5>
                                )
                            })}</div>
                            <div className="d-flex justify-content-center pt-3">
                                <button className="btn btn-primary py-2 px-5 generate-btn mb-5" onClick={() => { createPost() }}>
                                    Generate Ideas
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>        <Footer/></>

    )
}
export default Ideas;