import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Ideas from './components/Ideas';
import Card from './components/Card';
import Download from './components/Download';
import Data from './components/Admin';
import './App.css';

function App() {
  return (
  
      <div className="App">
        <Routes>
          <Route exact path="/" Component={Ideas}></Route>
          <Route exact path="/Card" Component={Card}></Route>
          <Route exact path="/Download" Component={Download}></Route>
          <Route exact path="/Data" Component={Data}></Route>
        </Routes>
      </div>

  );
}

export default App;
