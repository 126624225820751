import axios from "axios";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom"
const Data = () => {



    let navigate = useNavigate();
    const [post, setpost] = useState({
        data: "",
    });


    const { data } = post;
    const onInputChange = e => {
        setpost({ ...post, [e.target.name]: e.target.value });
    }

    const onSubmit = async e => {
        e.preventDefault();
        await axios.post(`http://localhost:8080/data`, post);
        navigate("/Card");
    }

    return (
        <div className="container mt-3">
            <div className="w-50 mx-auto shadow p-5">
                <h2 className="text-center mb-4">Blog Heading</h2>
                <form onSubmit={e => onSubmit(e)}>
                    <div className="input-group news-input">

                        <input style={{ textTransform: 'capitalize' }}
                            placeholder="Enter A Noun To Get Started"
                            type="text"
                            className="form-control border-1 rounded-start p-3"
                            name="data"
                            value={data}
                            onChange={e => onInputChange(e)} />
                        <button className="btn btn-primary btn-lg rounded-end p-2 px-4 input-btn" onChange={e => onSubmit(e)}>Add</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Data