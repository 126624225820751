import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import corner from './image/banner.png';
import Footer from  "../components/Footer";


const Download = () => {
  const [data, setData] = useState()
  const fetchJson = () => {
    fetch(`https://imgserver.onrender.com/datas`)
      .then(response => {
        return response.json();
      }).then(data => {
        setData(data);
      });
  }
  useEffect(() => {
    fetchJson()
  }, [])

  const csvHeader = [
    { label: "ID", key: "id" },
    { label: "DATA", key: "data" },
  ];

  let sessionvalue = sessionStorage.getItem("state")
  let state = sessionvalue.split(',');
  console.log(state);

  // Function to convert the JSON(Array of objects) to CSV.
  const arrayToCsv = (header, data) => {
    const csvRows = [];
   
    // Getting rows. 
    for (const row of data) {
      const rowValues = header.slice(1, 2).map(header => {
        const escaped = ('' + row[header.key]).replace('String', state[Math.floor(Math.random() * state.length)]); // To replace the unwanted quotes.
        return `"${escaped}"`; // To escape the comma in a address like string.
      });
      csvRows.push(rowValues.join(',')); // Push into array as comma separated values. 
    }
    return csvRows.join('\n'); // To enter the next rows in the new line '\n' 
  };

  // Function to download the generated CSV as a .csv file.
  const download = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  let navigate = useNavigate();
  const filename = 'blog';
  const generateCSV = () => {
    const csvData = arrayToCsv(csvHeader, data);
    download(csvData, filename);
    navigate("/")
  };

  return (
    <>
    <section className="home-banner height pt-lg-5">
      <div className="container pt-lg-3">
        <div className="corner-img">
          <img src={corner} alt="icons" className="img-fluid corner" />
        </div>
        <div className="text-center">
          <h1 className="mb-5">Free Blog Post Ideas Generator</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <div className="card text-dark border-0 py-3 px-3">
              <div className="text-dark fs-5 m-3">Your data submitted successfully...Now you can download the Blog Ideas</div>
              <div className="mb-4 mt-3">
                <button className="btn btn-outline-primary generate-btn py-2 px-3" onClick={generateCSV}>Download</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <Footer/> </>
  )
}

export default Download;